import axios from "./http.js";

//登录
const login = {
  login(params) {
    return axios.post("/login", params);
  },
  captchaImage(params) {
    return axios.get("/captchaImage", params);
  },
  register(params) {
    return axios.post("/register", params);
  },
};

//主页
const lifescan = {
  getScan(params) {
    return axios.get("/lifescan/getscan", { params });
  },
  getTimeScan(params) {
    return axios.get("/lifescan/gettimescan", { params });
  },
  updScanAccess(params) {
    return axios.post(`/lifescan/updscan/${params}`);
  },
  getIP() {
    return axios.get("/lifescan/getIP", {});
  },
  distribute(params) {
    return axios.post("/lifescan/distribute", params);
  },
  getCurrentLocation(params) {
    return axios.get(`/lifescan/currentLocation/${params}`);
  },
  rescueMembers(params){
    return axios.get("/lifescan/rescueMembers", { params });
  },

};

const api = {
  login,
  lifescan,
};

export default api;
